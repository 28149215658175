import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "currentColor",
  "fill-rule": "evenodd",
  "clip-rule": "evenodd",
  d: "M13 16C12.4477 16 12 16.4477 12 17C12 17.5523 12.4477 18 13 18H20.9998C20.9999 18 20.9999 18 21 18C21.1356 18 21.2649 17.973 21.3828 17.9241C21.5007 17.8753 21.6112 17.803 21.7071 17.7071C21.803 17.6112 21.8753 17.5007 21.9241 17.3828C21.9685 17.2755 21.9934 17.1621 21.9989 17.048C21.9995 17.0351 21.9998 17.0221 22 17.0092C22 17.0062 22 17.0031 22 17C22 16.9997 22 16.9993 22 16.999V9C22 8.44771 21.5523 8 21 8C20.4477 8 20 8.44771 20 9V14.5858L13.7071 8.29289C13.3166 7.90237 12.6834 7.90237 12.2929 8.29289L9 11.5858L3.70711 6.29289C3.31658 5.90237 2.68342 5.90237 2.29289 6.29289C1.90237 6.68342 1.90237 7.31658 2.29289 7.70711L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L13 10.4142L18.5858 16H13Z"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("path", _hoisted_1))
}